import React, { useState, useEffect } from 'react';
import './NewsletterHome.css'; // Import your custom CSS
import logo from './logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faSignOutAlt, faSignInAlt, faRobot } from '@fortawesome/free-solid-svg-icons';
import { faDatabase } from '@fortawesome/free-solid-svg-icons'; // Add this line for the Record Manager icon


function NewsletterHome() {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => setCurrentDateTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="newsletter-home">
      <div className="header">
        <img src={logo} alt="Company Logo" className="logo" />

        <h1 className="company-name">Married to the Mop</h1>
      </div>

      <p className="welcome-message">Welcome to our Newsletter Dashboard!</p>

      <div className="datetime">
        <p>{currentDateTime.toLocaleDateString()}</p>
        <p>{currentDateTime.toLocaleTimeString()}</p>
      </div>

      <div className="button-group">
      <a href="http://compose.marriedtothemopnewsletter.online" className="btn">
          <FontAwesomeIcon icon={faEnvelope} /> Compose Email
        </a>
        <a href="http://unsubscribe.marriedtothemopnewsletter.online" className="btn">
          <FontAwesomeIcon icon={faSignOutAlt} /> Unsubscribe
        </a>
        <a href="http://subscribe.marriedtothemopnewsletter.online" className="btn">
          <FontAwesomeIcon icon={faSignInAlt} /> Subscribe
        </a>
        <a href="https://chatgpt.com/" className="btn">
          <FontAwesomeIcon icon={faRobot} /> Email with AI
        </a>
        <a href="http://recordmanager.marriedtothemopnewsletter.online" className="btn">
        <FontAwesomeIcon icon={faDatabase} /> Record Manager
        </a>

        {/* <a href="http://compose.marriedtothemopnewsletter.online" className="btn">Compose Email</a>
        <a href="http://unsubscribe.marriedtothemopnewsletter.online" className="btn">Unsubscribe</a>
        <a href="http://subscribe.marriedtothemopnewsletter.online" className="btn">Subscribe</a>
        <a href="http://aiemail.marriedtothemopnewsletter.online" className="btn">Email with AI</a> */}
      </div>
    </div>
  );
}

export default NewsletterHome;
